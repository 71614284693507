<template>
  <div class="bg-dark fullscreen flex flex-center">
    <div
      class="atropos cursor-pointer"
      ref="atroposRef"
      style="overflow: visible"
      @click="onClick()"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <div class="atropos-scale">
        <div class="atropos-rotate">
          <div class="atropos-inner">
            <div class="fit relative-position">
              <q-card
                class="column items-center justify-center window-height window-width q-pa-lg transparent"
                flat
              >
                <q-card-section
                  class="q-mb-md q-pa-none"
                  data-atropos-offset="5"
                  :horizontal="$q.screen.gt.sm"
                  style="width: 700px; max-width: 80vw"
                >
                  <q-card-section class="col-8 column">
                    <q-img
                      class="q-mb-lg"
                      src="~/assets/quad.svg"
                      height="64px"
                      width="260px"
                    />
                    <span :class="$q.screen.lt.md ? 'text-h4' : 'text-h3'">{{
                      t("title")
                    }}</span>
                  </q-card-section>
                  <q-card-section
                    :class="$q.screen.gt.sm ? 'items-end' : 'items-start'"
                    class="col-4 column justify-end"
                  >
                    <q-btn
                      color="primary"
                      label="Continue"
                      no-caps
                      :outline="!hover"
                      square
                    />
                  </q-card-section>
                </q-card-section>
                <q-separator style="width: 700px; max-width: 80vw" />
                <q-item
                  class="q-pa-md"
                  data-atropos-offset="5"
                  style="width: 700px; max-width: 80vw"
                >
                  <q-item-section>
                    <q-item-label>Chris Quezada</q-item-label>
                    <q-item-label caption>Cover Letter</q-item-label>
                  </q-item-section>
                </q-item>
              </q-card>
              <q-card></q-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, nextTick, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useAppStore } from "src/stores/app";

defineOptions({ name: "SplashScreen" });

const { t } = i18n.global;
const appStore = useAppStore();
const { splashClicked } = storeToRefs(appStore);
const atropos = inject("atropos");
const atroposRef = ref(null);
const hover = ref(false);

const onClick = () => {
  splashClicked.value = true;
};

onMounted(async () => {
  await nextTick();
  const myAtropos = atropos({
    el: atroposRef.value,
  });
});
</script>
