<template>
  <div
    class="atropos overflow-visible"
    :id="section.slug.current"
    ref="atroposRef"
  >
    <div class="atropos-scale overflow-visible">
      <div class="atropos-rotate overflow-visible">
        <div class="atropos-inner overflow-visible">
          <router-link
            class="no-text-underline"
            :class="{ 'no-cursor-pointer': active }"
            :to="
              active
                ? { path: '/' }
                : {
                    name: 'section',
                    params: { section: section.slug.current },
                  }
            "
          >
            <div
              class="full-width overflow-hidden relative-position"
              :style="styleNav"
            >
              <section-row-video
                v-if="section.project.media[0].videoUrl"
                :active="active"
                :class="active ? 'absolute-top' : 'absolute-center'"
                :hover="hover"
                :new-time="videoNewTime"
                :poster="responsivePoster"
                :ratio="section.project.ratio || 16 / 9"
                :restart="videoRestart"
                :section="section"
                :src="section.project.media[0].videoUrl"
                :width="computedWidth"
                @loaded="(player) => onLoaded(player)"
                @updated="(player) => onUpdated(player)"
              />
              <section-row-swiper
                v-else-if="!$q.platform.is.mobile"
                :active="active"
                :class="active ? 'absolute-top' : 'absolute-center'"
                :hover="hover"
                :media="section.project.media"
                :ratio="section.project.ratio || 16 / 9"
                :width="computedWidth"
              />
              <image-component
                v-else
                :class="active ? 'absolute-top' : 'absolute-center'"
                :crop="false"
                :image="section.project.media[0].image"
                :ratio="section.project.ratio || 16 / 9"
              />
            </div>
            <div
              :class="[
                $q.screen.lt.md ? 'q-pl-md' : 'q-pl-xl',
                active
                  ? `items-start ${$q.screen.lt.md ? 'q-py-lg' : 'q-py-xl'}`
                  : 'items-center',
              ]"
              class="absolute-full atropos-label justify-between row z-top"
              data-atropos-offset="10"
              ref="labelRef"
              :style="
                active
                  ? {
                      position: 'sticky',
                      marginTop: `calc(${-width}px / ${
                        section.project.ratio || 16 / 9
                      })`,
                      top: 0,
                    }
                  : null
              "
            >
              <section-row-label
                :active="active"
                class="cursor-pointer"
                :hover="active || hover"
                :section="section"
              />
              <section-close
                v-if="active"
                :class="$q.screen.lt.md ? 'q-mr-md' : 'q-mr-xl'"
                @click="active ? onClick() : null"
              />
            </div>
          </router-link>
          <div v-show="active" class="absolute-top" :style="styleNav">
            <div v-if="section.project.media[0].videoUrl">
              <video-player
                class="absolute-bottom q-mx-auto"
                :duration="videoDuration"
                :project="section.project"
                style="width: 640px; max-width: 80vw"
                :time="videoCurrentTime"
                @change="(value) => onChange(value)"
                @restart="() => (videoRestart = !videoRestart)"
              />
            </div>
          </div>
          <div
            v-show="active"
            :style="{
              marginTop: `calc(calc(${width}px / ${
                section.project.ratio || 16 / 9
              }) - ${scale}px`,
            }"
          >
            <div class="row items-center justify-center">
              <div
                v-if="section.body"
                class="full-width row items-center justify-center"
              >
                <body-component class="q-mx-auto" :data="section" />
              </div>
              <q-separator v-if="section.body" class="q-mx-auto max-ch-80" />
              <div :class="contentClass">
                <div class="max-ch-80 q-mx-auto q-pt-lg">
                  <div class="q-mb-md q-mx-auto">
                    <div class="full-width items-center justify-between row">
                      <div>
                        <div class="text-weight-medium">
                          {{ section.project.title }}
                        </div>
                        <div class="text-caption text-grey">
                          <div v-if="section.project.agency" class="">
                            {{ section.project.agency }}
                          </div>
                          <div v-if="section.project.freelance" class="">
                            {{ section.project.freelance }}
                          </div>
                          <div v-if="section.project.company" class="">
                            {{ section.project.company }}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div caption class="text-weight-light">
                          {{ section.project.roles }}
                        </div>
                        <div class="text-caption text-grey">
                          <span v-if="section.project.position"
                            >{{ section.project.position }} /</span
                          >
                          {{ section.project.year }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    :class="$q.screen.lt.md ? 'text-body1' : 'text-h6'"
                    class="q-my-lg max-ch-50 text-grey text-weight-light"
                    style="line-height: 1.3125"
                  >
                    {{ section.project.description }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="section.appUrl"
              class="items-center justify-center q-py-lg row"
            >
              <q-btn
                class="cursor-pointer"
                color="primary"
                :label="section.appLabel"
                no-caps
                size="lg"
                square
                @click="onButtonClick()"
              />
            </div>
            <div
              v-if="section.project.media.length"
              class="items-center justify-start q-mt-lg row"
            >
              <div
                v-for="media in section.project.media.slice(1)"
                class="col-xs-12 col-md-6 overflow-hidden relative-position"
                :key="media._key"
              >
                <template v-if="media.type === 'image'">
                  <template v-if="media.rowRatio">
                    <q-responsive :ratio="media.rowRatio">
                      <image-component :crop="false" :image="media.image" />
                    </q-responsive>
                  </template>
                  <template v-else-if="media.scroll">
                    <q-responsive :ratio="section.project.ratio || 16 / 9">
                      <q-scroll-area
                        class="fit"
                        style="overflow-x: hidden"
                        :thumb-style="thumbStyle"
                        visible
                      >
                        <image-component :crop="false" :image="media.image" />
                      </q-scroll-area>
                    </q-responsive>
                  </template>
                  <image-component
                    v-else
                    :crop="!media.scroll"
                    :image="media.image"
                  />
                </template>
                <template v-else>
                  <section-row-video
                    active
                    :ratio="16 / 9"
                    row
                    :section="section"
                    :src="media.videoUrl"
                    :width="
                      $q.screen.lt.md ? $q.screen.width : $q.screen.width / 2
                    "
                  />
                </template>
              </div>
            </div>
            <div
              v-if="section.appUrl"
              class="items-center justify-center q-py-lg row"
            >
              <q-btn
                class="cursor-pointer"
                color="primary"
                :label="section.appLabel"
                no-caps
                size="lg"
                square
                @click="onButtonClick()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, ref, watch } from "vue";
import { colors, openURL, useQuasar } from "quasar";
import { storeToRefs } from "pinia";
import { urlFor } from "src/boot/sanityUrlFor";
import { useAppStore } from "src/stores/app";
import BodyComponent from "../body/BodyComponent.vue";
import ImageComponent from "../image/ImageComponent.vue";
import SectionClose from "./SectionClose.vue";
import SectionRowLabel from "./SectionRowLabel.vue";
import SectionRowSwiper from "./SectionRowSwiper.vue";
import SectionRowVideo from "./SectionRowVideo.vue";
import VideoPlayer from "../video/VideoPlayer.vue";
import { useResponsiveImageWidth } from "src/composables/image/useResponsiveImageWidth";

defineOptions({ name: "SectionRow" });

const emits = defineEmits(["resize"]);

const props = defineProps({
  active: { type: Boolean, default: false },
  length: { type: Number, default: 7 },
  section: {
    type: Object,
    default: () => ({}),
  },
  height: { type: Number, default: 960 },
  width: { type: Number, default: 480 },
});

const { getPaletteColor } = colors;
const $q = useQuasar();
const atropos = inject("atropos");
const appStore = useAppStore();
const { activeIntersection, closeSection, contentClass } =
  storeToRefs(appStore);
const atroposRef = ref(null);
const computedActive = computed(() => props.active);
const computedHeight = computed(() => props.height);
const computedWidth = computed(() => props.width);
const hover = ref(false);
const labelRef = ref(null);
const percent = ref(0);
const responsiveWidth = computed(() => useResponsiveImageWidth(640));
const responsivePoster = computed(() => {
  const imageUrl = props.section.project.media[0].image?.asset?.url || null;
  if (imageUrl) {
    return urlFor(imageUrl).width(Math.round(responsiveWidth.value)).url();
  }
  return null;
});
const rotateXMax = ref(15);
const rotateYMax = ref(22);
const scale = computed(() => ($q.screen.lt.md ? 80 : 140));
const styleNav = computed(() => ({
  height: computedActive.value
    ? `calc(${computedWidth.value}px / ${
        props.section.project.ratio || 16 / 9
      })`
    : `${computedHeight.value}px`,
}));
const thresholds = [];
for (let i = 0; i <= 1.0; i += 0.01) {
  thresholds.push(i);
}
const thumbColor = ref("#fff");
const thumbStyle = computed(() => {
  return {
    borderRadius: "0px",
    backgroundColor: thumbColor.value,
    opacity: 0.6,
    zIndex: 999,
  };
});
const videoCurrentTime = ref(0);
const videoNewTime = ref(0);
const videoDuration = ref(0);
const videoRestart = ref(false);
const options = {
  handler(entry) {
    const val = (entry.intersectionRatio * 100).toFixed(0);
    if (percent.value !== val) {
      percent.value = val;
    }
  },
  cfg: {
    threshold: thresholds,
  },
};
let myAtropos;

const checkActive = async () => {
  await nextTick();
  if (computedActive.value) {
    if (myAtropos) {
      myAtropos.params.activeOffset = 0;
      myAtropos.params.rotate = false;
      resetTransformations();
    }
  } else {
    myAtropos.params.activeOffset = 50;
    myAtropos.params.rotate = true;
  }
};

const createAtropos = async () => {
  await nextTick();
  myAtropos = atropos({
    el: atroposRef.value,
    highlight: false,
    rotateTouch: "scroll-y",
    rotateXMax: rotateXMax.value,
    rotateYMax: rotateYMax.value,
    onEnter() {
      hover.value = true;
    },
    onLeave() {
      hover.value = false;
    },
  });
};

const onChange = (value) => {
  videoNewTime.value = value;
};

const onButtonClick = () => {
  openURL(props.section.appUrl);
};

const onClick = () => {
  closeSection.value = props.section.slug.current;
};

const onLoaded = (player) => {
  videoDuration.value = player.duration();
};

const onUpdated = (player) => {
  videoCurrentTime.value = player.currentTime();
};

const resetTransformations = () => {
  const el = atroposRef.value;
  if (el) {
    const rotateEl = el.querySelector(".atropos-rotate");
    if (rotateEl) {
      rotateEl.style.transform =
        "translate3d(0%, 0%, 0px) rotateX(0deg) rotateY(0deg)";
    }
    const scaleEl = el.querySelector(".atropos-scale");
    if (scaleEl) {
      scaleEl.style.transform = "translate3d(0px, 0px, 0px)";
    }
    const labelEl = el.querySelector(".atropos-label");
    if (labelEl) {
      labelEl.style.transform = "translate3d(0px, 0px, 0px)";
    }
  }
};

onMounted(() => {
  createAtropos();
  checkActive();
  thumbColor.value = getPaletteColor("primary");
});

watch(computedActive, () => {
  checkActive();
});

watch(percent, (newPercent) => {
  if (!props.active) return;
  activeIntersection.value = newPercent >= 3;
});
</script>
